/*
* PERCORSI 
 */

jQuery(function($) {
    var offset = 0; // Inizializza l'offset a 0
    var currentTaxonomyId = 58; // Imposta un valore speciale 'all' per indicare tutti i percorsi

    // Carica i post quando la pagina viene caricata senza specificare un ID
    loadPosts(currentTaxonomyId, offset);

    $(document).on('click', '.cta_azioni_articoli', function(e) {
        e.preventDefault();
    
        // Rimuovi la classe 'active' da tutti i pulsanti e aggiungila a quello cliccato
        $('.cta_azioni_articoli').removeClass('active');
        $(this).addClass('active');
    
        // Recupera l'ID della tassonomia dal bottone cliccato o usa 'all' se vuoi caricare tutti i percorsi
        var termId = $(this).data('taxonomy-id') || 'all';
    
        // Svuota il contenitore prima di aggiungere nuovi elementi
        $('#article_container').empty();
    
        // Azzera l'offset e ricarica i post con la nuova tassonomia
        offset = 0;
        loadPosts(termId, offset);
    });

    $(document).on('click', '#load-more', function(e) {
        e.preventDefault();
        offset += 6; // Assumi che 6 sia il numero di post per pagina
        var termId = $('.cta_azioni_articoli.active').data('taxonomy-id') || 'all';
        loadPosts(termId, offset);
    });

    function loadPosts(termId, offset) {
        var data = {
            'action': 'filter_posts',
            'term_id': termId, // Passa 'all' o l'ID della tassonomia
            'offset': offset,
        };
    
        $.post(my_script_vars.ajaxurl, data, function(response) {
            var result = JSON.parse(response);
            
            var newElements = $(result.html);
            newElements.each(function() {
                var id = $(this).data('id');
                
                // Se l'elemento non è già presente, aggiungilo
                if ($('#article_container [data-id="' + id + '"]').length === 0) {
                    $('#article_container').append(this);
                }
            });
            
            // Gestisci la visibilità del pulsante "Carica altro"
            if (result.next_offset >= result.found_posts) {
                $('#load-more').hide();
            } else {
                $('#load-more').show();
            }
        });
    }
});








/*
*   EXPERIENCE 
 */


jQuery(function($) {
    var offset = 0; // Inizializza l'offset a 0
    var currentTaxonomyId = 'all'; // Imposta un valore speciale 'all' per indicare tutti i percorsi

    // Carica i post quando la pagina viene caricata senza specificare un ID
    loadPosts_experience(currentTaxonomyId, offset);

    $(document).on('click', '.cta_azioni_esperienze', function(e) {
        e.preventDefault();
    
        // Rimuovi la classe 'active' da tutti i pulsanti e aggiungila a quello cliccato
        $('.cta_azioni_esperienze').removeClass('active');
        $(this).addClass('active');
    
        // Recupera l'ID della tassonomia dal bottone cliccato o usa 'all' se vuoi caricare tutti i percorsi
        var termId = $(this).data('taxonomy-id') || 'all';
    
        // Svuota il contenitore prima di aggiungere nuovi elementi
        $('#esperienze_container_riepilogo').empty();
    
        // Azzera l'offset e ricarica i post con la nuova tassonomia
        offset = 0;
        loadPosts_experience(termId, offset);
    });

    $(document).on('click', '#load-more-esperienze', function(e) {
        e.preventDefault();
        offset += 6; // Assumi che 6 sia il numero di post per pagina
        var termId = $('.cta_azioni_esperienze.active').data('taxonomy-id') || 'all';
        loadPosts_experience(termId, offset);
    });

    function loadPosts_experience(termId, offset) {
        var data = {
            'action': 'filter_esperienze',
            'term_id': termId, // Passa 'all' o l'ID della tassonomia
            'offset': offset,
        };
    
        $.post(my_script_vars.ajaxurl, data, function(response) {
            var result = JSON.parse(response);
            
            var newElements = $(result.html);
            newElements.each(function() {
                var id = $(this).data('id');
                
                // Se l'elemento non è già presente, aggiungilo
                if ($('#esperienze_container_riepilogo [data-id="' + id + '"]').length === 0) {
                    $('#esperienze_container_riepilogo').append(this);
                }
            });
            
            // Gestisci la visibilità del pulsante "Carica altro"
            if (result.next_offset >= result.found_posts) {
                $('#load-more-esperienze').hide();
            } else {
                $('#load-more-esperienze').show();
            }
        });
    }
});
/* 
//FORM RICHIESTE PRENOTAZIONI

jQuery(document).ready(function(){
	jQuery('#privacy-label').html('&nbsp; Accetto l\'informativa sulla <a target="_blank" style="color:#35c1b8;" href="https://offerte.adlon.it/privacy-policy/">privacy</a>');
	var oggi = new Date();
	oggi.setDate(oggi.getDate() + 1);
	jQuery('#dp1').datepicker({
		dateFormat: 'yy-mm-dd',
		showButtonPanel: true,
		minDate: oggi,
		inline: true,
		onSelect: function (date) {
			var dp2 = jQuery('#dp2');
			var startDate = jQuery(this).datepicker('getDate');
			var minDate = jQuery(this).datepicker('getDate');
			startDate.setDate(startDate.getDate() + 1);
			dp2.datepicker('option', 'minDate', startDate);
		}
	});
	
	jQuery('#dp2').datepicker({
		showButtonPanel: true,
		inline: true,
		dateFormat: 'yy-mm-dd'
	});
	
	jQuery(".ui-datepicker-trigger").css("height","32px");
	
	
	jQuery('#Children').change(function(){
		var children = jQuery('#Children').val();
		if (children == 0) {
		   jQuery('#etabambini').css("display","none");
		} else {
		   jQuery('#etabambini').css("display","flex");
		   for (var i=1;i <= 6;i++) {
			   if (i <= children) {
				   jQuery('#eta-'+i).css("display","block");
			   } else {
				   jQuery('#eta-'+i).css("display","none");
			   }
			}
		}
	});
	
	jQuery('#EmailAddress').on('input', function() {
		var textinside = jQuery(this).val();
		var expr = /@/;
		if (expr.test(textinside)) {
			jQuery('#cognitivo1').css("display","inline");
			jQuery('#cognitivo2').css("display","inline");
			var Children = jQuery('#Children').val();
			if (Children !=0 ) {
				jQuery('#etabambini').css("display","flex");  
			}
		} else {
			jQuery('#cognitivo1').css("display","none");
			jQuery('#cognitivo2').css("display","none");
			 jQuery('#etabambini').css("display","none");  
		}
	
	});
	
	//RIMUOVI ERRORI
	jQuery('#FirstName').on('focus', function() {
	   jQuery(this).removeClass('error'); 
	});
	 jQuery('#LastName').on('focus', function() {
	   jQuery(this).removeClass('error'); 
	});
	jQuery('#EmailAddress').on('focus', function() {
		jQuery(this).removeClass('error');     
	});
	jQuery('#privacy').click(function(){
	   jQuery('#privacy-div').removeClass('error'); 
	});
	
	
	//INVIO
	jQuery('#invio').click(function() {
		
		//CAMPI RELATIVI ALLA CAMPAGNA DA EDITARE
				var SourceOfBusiness = 'Email marketing';
		var PromotionVendor = 'Newsletter';
		var Promotion = 'Gennaio 2024 family (in3pida)';
		var ThirdPartyNotes = '';
		
		// DATI LINGUA
		var LanguageCode = 'it';
		var thankyou_url = 'https://mariod77.sg-host.com/grazie-per-averci-contattato/';
		
		//DATI FORM
		var FirstName = jQuery('#FirstName').val();
		var LastName = jQuery('#LastName').val();
		var EmailAddress = jQuery('#EmailAddress').val();
		var PhoneNumber = jQuery('#PhoneNumber').val();
		var ArrivalDate = jQuery('#dp1').val();
		var DepartureDate = jQuery('#dp2').val();
		var Adults = Number(jQuery('#Adults').val());
		var Children = Number(jQuery('#Children').val());
		var ChildrenAges = new Array();
		if (Children != 0) {
			for (var i=1;i<=Children;i++) {
				var eta = Number(jQuery('#veta-'+i).val());
				ChildrenAges.push(eta);
			}
		}
		var Text = jQuery('#text').val();
		if (Text == 'il tuo messaggio') { Text = '';}
		if (jQuery('#newsletter').prop('checked')) {
			var newsletter = true;
		} else {
			var newsletter = false;
		}
	   
		//Checks
		var errors = 0;
		function checkEmail() {
			var email = jQuery('#EmailAddress').val();
			if (email == '') {
				return true;
			}
			var expr = /@/;
			if (!expr.test(email)) {
				return true;
			}
			return false;
		}
		
		if (ArrivalDate == '') {
			errors =1;
			jQuery('#dp1').addClass('error');
		}
		
		if (DepartureDate == '') {
			errors = 1;
			jQuery('#dp2').addClass('error');
		}
		
		if (Adults == 0) {
			errors = 1;
			jQuery('#Adults').addClass('error');
		}

		if (Children !=0) {
			for (var i=1;i<=Children;i++) {
				if ( isNaN(ChildrenAges[i-1]) ) {
					jQuery('#veta-'+i).addClass('error');
					errors = 1;
				}
			}

		}
		
		if (FirstName == '') {
			errors = 1;
			jQuery('#FirstName').addClass('error');
		}
		if (LastName == '') {
			errors = 1;
			jQuery('#LastName').addClass('error');
		}
		var EmailError = checkEmail();
		if (EmailError) {
			errors = 1;
			jQuery('#EmailAddress').addClass('error');
		}
		if (!jQuery('#privacy').prop('checked')) {
			errors = 1;
			jQuery('#privacy-div').addClass('error');
		}

				
		
		if (errors == 0) {
			jQuery.ajax({
				type: 'POST',
				url: 'https://adlon-it.reguest-hub-api.reguest.io', // Utilizza l'URL fornito da wp_localize_script
				datatype: 'application/json',
				data: {
					'LanguageCode': LanguageCode,
					'PromotionVendor': PromotionVendor,
					'Promotion': Promotion,
					'SourceOfBusiness': SourceOfBusiness,
					'FirstName': FirstName,
					'LastName': LastName,
					'EmailAddress': EmailAddress,
					'PhoneNumber': PhoneNumber,
					'ArrivalDate': ArrivalDate,
					'DepartureDate': DepartureDate,
					'Adults': Adults,
					'Children': Children,
					'ChildrenAges': ChildrenAges,
					'Text': Text,
					'NewsletterSubscription': newsletter
				},
                success: function (res) {
                    window.open(thankyou_url, '_blank');
                    console.log('Successo:', res);
                },
                error: function (xhr, status, error) {
                    console.log('Errore:', status, error);
                }
			});
		}
   });
});
 */